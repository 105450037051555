const pageContent = {
  pageTitle: "404 - Lokalizacja nie znaleziona!",
  title: "Lokalizacja\n nie znaleziona!",
  subtitleOne: "Potrzebujesz pomocy\n w poruszaniu sie po przestrzeni?",
  subtitleTwo: "Może to czas, by poznać nas bliżej?",
  miniNav: [
    {
      path: "/",
      name: "Strona główna",
    },
    {
      path: "/offer",
      name: "Oferta",
    },
    {
      path: "/contact",
      name: "Kontakt",
    },
  ],
};

export { pageContent };
