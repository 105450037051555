import React from "react";
import ErrorPage from "components/ErrorPage";
import Seo from "utils/Seo";
import { pageContent } from "staticContent/404";

const IndexPage = () => (
  <>
    <Seo pageSubtitle={pageContent.pageTitle} />
    <ErrorPage />
  </>
);

export default IndexPage;
