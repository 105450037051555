import React from "react";
import { Link } from "gatsby";
import { pageContent } from "staticContent/404";
import Error404 from "./Error404";
import Space from "./Space";
import "styles/components/errorPage.scss";

const navItems = pageContent.miniNav.map(({ name, path }, index) => (
  <p key={index} className="error__nav-item">
    <Link to={path}>{name}</Link>
  </p>
));

const ErrorPage = () => (
  <div className="error-wrapper">
    <div className="notfound-copy">
      <Error404 />
      <h1 className="error__title--h1">{pageContent.title}</h1>
      <h2 className="error__title--h2">{pageContent.subtitleOne}</h2>
      <h2 className="error__title--h2">{pageContent.subtitleTwo}</h2>
      <nav className="error__nav">{navItems}</nav>
    </div>
    <Space />
  </div>
);

export default ErrorPage;
